@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap);
* { margin: 0; padding: 0; }
* {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}

/*hide capture badge*/
.grecaptcha-badge { opacity:0;}

:root {
    --url-color: #ED1C24;
    --text-color: #3a3a3a;
    --bg-color-main: #F7F7F7;
    --bg-color-secondary: #E7F8FF;
    --bg-color-third: #E5E5E5;

    --logo-text-size: 70px;
    --heading-text-size: 40px;
    --subheading-text-size: 28px;
    --section-text-size: 20px;
    --subsection-text-size: 18px;
    --main-text-size: 15px;

    --main-padding: 30px;
    --half-padding: 15px;
}

body {
    background-color: #F7F7F7;
    background-color: var(--bg-color-main);
    font-family: 'Montserrat', SansSerif, serif;
    font-weight: 400;
    color: #3a3a3a;
    color: var(--text-color);
    line-height: 1.4;
}

h1 {
    font-weight: 700;
    font-size: 40px;
    font-size: var(--heading-text-size);
}

h2 {
    font-weight: 700;
    font-size: 28px;
    font-size: var(--subheading-text-size);
}

h3 {
    font-weight: 700;
    font-size: 20px;
    font-size: var(--section-text-size);
}

h4 {
    font-weight: 700;
    font-size: 15px;
    font-size: var(--main-text-size);
}


p {
    font-size: 15px;
    font-size: var(--main-text-size);
}

a {
    color: #ED1C24;
    color: var(--url-color);
    text-decoration: none;
}

div, p { /* Safari/Chrome, other WebKit */    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

.separator {
    height: 1px;
    margin: 0;
    padding: 0;
    border-bottom: #cccccc 1px solid;
}

.displayMobile {
    display: none;
}

.displayWeb {
    display: block;
}

.shadow {
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
}

.border {
    border: #cccccc 1px solid;
}

/*
/ MAIN CONTAINER
 */

.wrapper {
    display: flex;
    justify-content: center;
}

.container {
    width: 100%;
    height: 100vh;
}

.separator {
    height: 10px;
}

.text-overflow-hidden {
    overflow: hidden;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;
}



/*
/ Controls
 */

.controlsHeaderContainer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.cardsContainer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 10px 0;
}

.cardsContainer-box {
    width: 100%;
    overflow-x: scroll;
}

.whiteBox {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 6px;
}

.card-image-container {
    width: 100%;
    height: 80px;
}

.card-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
}

.card-activity-container {
    padding: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    display: flex;
    margin-bottom: 20px;
}

/*
/* POPUPS
 */

.popup {
    position: fixed;
    z-index: 200;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.7);
}
.popup_inner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #000;
    outline: none;
    line-height: 1.2;
}

.popup_inner_close {
    position: absolute;
    right: 20px;
    top: 20px;
}

.popup_inner_right {
    position: absolute;
    right: 20px;
    top: 50vh;
}

.popup_inner_left {
    position: absolute;
    left: 20px;
    top: 50vh;
}

/*
/ Buttons
 */

button {
    font-size: 15px;
    font-size: var(--main-text-size);
    cursor: pointer;
    outline: none;
}

.button {
    padding: 15px 25px;
    background-color: #ED1C24;
    background-color: var(--url-color);
    border-width: 0;
    font-size: 15px;
    font-size: var(--main-text-size);
    font-weight: bold;
    color: #FFFFFF;
    cursor: pointer;
    outline: none;
    border-radius: 25px;
}

.button-round {
    width: 44px;
    height: 44px;
    background-color: rgba(255, 255, 255, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: unset;
}

.button-clean {
    padding: 15px 25px;
    border-radius: 25px;
    border: #ED1C24 1px solid;
    border: var(--url-color) 1px solid;
    background-color: #ffffff;
    color: #ED1C24;
    color: var(--url-color);
}

.button-clean-small {
    padding: 8px 15px;
    border-radius: 25px;
    border: #ED1C24 1px solid;
    border: var(--url-color) 1px solid;
    background-color: #ffffff;
    color: #ED1C24;
    color: var(--url-color);
}

.button-clean-small:disabled {
    opacity: .3;
}

.button-small {
    background-color: #ED1C24;
    background-color: var(--url-color);
    border-width: 0;
    font-size: 15px;
    font-size: var(--main-text-size);
    color: #FFFFFF;
    cursor: pointer;
    outline: none;
    border-radius: 25px;
    padding: 8px 12px;
    font-weight: normal;
}

.button-link {
    padding: 0;
    background-color: unset;
    border: unset;
    color: #ED1C24;
    color: var(--url-color);
    font-weight: normal;
    text-decoration: underline;
}

.button-link-small {
    padding: 0;
    background-color: unset;
    border: unset;
    color: #ED1C24;
    color: var(--url-color);
    font-weight: normal;
    text-decoration: underline;
    font-size: 14px;
}

.button-link-regular {
    background-color: unset;
    padding: 5px 10px;
    border-radius: 25px;
    border: transparent 1px solid;
    color: #ED1C24;
    color: var(--url-color);
    text-underline-color: #ED1C24;
    text-underline-color: var(--url-color);
    font-weight: normal;
    text-decoration: underline;
}

.button-link-selected {
    background-color: #ffffff;
    padding: 5px 10px;
    border-radius: 25px;
    border: transparent 1px solid;
    color: #ED1C24;
    color: var(--url-color);
    text-decoration: none;
    font-weight: normal;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
}

input, select, textarea {
    min-width: 150px;
    max-width: 400px;
    width: 100%;
    height: 34px;
    padding: 5px 25px 5px 15px;
    font-size: 15px;
    font-size: var(--main-text-size);
    outline: none;
    border-radius: 10px;
    border: #FFFFFF 1px solid;
    background-color: #ffffff;
    color: #3a3a3a;
    color: var(--text-color);
    -ms-box-sizing:border-box;
    box-sizing:border-box;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

select {
    opacity: .8;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    font-size: 12px;
    color: #cccccc;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    font-size: 12px;
    color: #cccccc;
}

input::placeholder, textarea::placeholder {
    font-size: 12px;
    color: #cccccc;
}

select {
    background: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E) no-repeat;
    -webkit-appearance: none;
    appearance: none;
    background-size: .65em;
    background-position: calc(100% - 1em) center;
    background-color: #ffffff;

}

textarea {
    resize: none;
    height: 200px;
    max-width: 100%;
}

textarea::-webkit-input-placeholder {
    color: #a0a0a0;
    font-family: "Montserrat", SansSerif, serif;
}

textarea:-ms-input-placeholder {
    color: #a0a0a0;
    font-family: "Montserrat", SansSerif, serif;
}

textarea::placeholder {
    color: #a0a0a0;
    font-family: "Montserrat", SansSerif, serif;
}


.yellow {
   background-color: #FFEDAC;
}

.blue {
    background-color: #039BE5;
    color: #FFFFFF;
}

.green {
    background-color: #E9FFAC;
}

.gray {
    background-color: #f0f0f0;
}

.white {
    background-color: #ffffff;
}

.slot {
    border-radius: 8px;
    padding: 4px 8px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
    margin-left: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.disabled {
    cursor: default;
}

.disabledClick {
    cursor: default;
    pointer-events: none;
    opacity: .3;
}

.enabledClick {
    cursor: pointer;
    pointer-events: auto;
    opacity: 1;
}

.patientContainer {
    max-height: 300px;
    overflow-y: scroll;
    padding: 15px;
    padding: var(--half-padding);
}

.paddingHalf {
    padding: 15px;
    padding: var(--half-padding);
}

/*
/ Other
 */

.alert-color {
    color: #ff0000;
}

.passive-color {
    color: #aaaaaa;
}

/*
/ Media Queries
 */

@media (min-width: 0px) and (max-width:980px) {

    .flex2col {
        display: block;
    }

    .separation-section {
        display: block;
    }

    .left {
        width: 100%;
        margin-bottom: 20px;
    }

    .right {
        width: 100%
    }

    .header-img-container {
        display: none;
    }
}

@media (min-width: 600px) and (max-width:1090px) {
}

@media (min-width: 450px) and (max-width:600px) {
    :root {
        --heading-text-size: 30px;
        --section-text-size: 18px;
        --subheading-text-size: 20px;
        --main-padding: 20px;
    }
}

@media (min-width: 0px) and (max-width:450px) {
    :root {
        --logo-text-size: 46px;
        --heading-text-size: 26px;
        --section-text-size: 16px;
        --subheading-text-size: 15px;
        --main-text-size: 14px;
        --main-padding: 20px;
        --half-padding: 10px;
    }

    .activity {
        width: 100%;
        margin-right: 15px;
        margin-bottom: 20px;
    }

    .displayMobile {
        display: block;
    }

    .displayWeb {
        display: none;
    }

}

@media (min-width: 0px) and (max-width:330px) {
    :root {
        --logo-text-size: 40px;
        --heading-text-size: 24px;
        --subheading-text-size: 15px;
        --main-text-size: 13px;
        --main-padding: 15px;
        --half-padding: 8px;
    }

}

/* margin ----------- */
.no-m {margin: 0}
.no-mt {margin-top: 0}
.no-mb {margin-bottom: 0}

.m-0 {margin: 0}
.m-10 {margin: 10px}
.m-15 {margin: 15px}
.m-20 {margin: 20px}
.m-30 {margin: 30px}

/* margin-top */
.mt-0 {margin-top: 0}
.mt-5 {margin-top: 5px}
.mt-10 {margin-top: 10px}
.mt-15 {margin-top: 15px}
.mt-20 {margin-top: 20px}
.mt-30 {margin-top: 30px}
.mt-40 {margin-top: 40px}
.mt-50 {margin-top: 50px}

/* margin-bottom */
.mb-0 {margin-bottom: 0}
.mb-5 {margin-bottom: 5px}
.mb-10 {margin-bottom: 10px}
.mb-15 {margin-bottom: 15px}
.mb-20 {margin-bottom: 20px}
.mb-30 {margin-bottom: 30px}
.mb-40 {margin-bottom: 40px}
.mb-50 {margin-bottom: 50px}

/* margin-left */
.ml-5 {margin-left: 5px}
.ml-10 {margin-left: 10px}
.ml-15 {margin-left: 15px}
.ml-20 {margin-left: 20px}
.ml-25 {margin-left: 25px}
.ml-30 {margin-left: 30px}

/* margin-right */
.mr-5 {margin-right: 5px}
.mr-10 {margin-right: 10px}
.mr-15 {margin-right: 15px}
.mr-20 {margin-right: 20px}
.mr-25 {margin-right: 25px}
.mr-30 {margin-right: 30px}

/* padding ----------- */

.p-10 {padding: 10px}
.p-15 {padding: 15px}
.p-20 {padding: 20px}
.p-30 {padding: 30px}

/* padding-top */
.pt-10 {padding-top: 10px}
.pt-15 {padding-top: 15px}
.pt-20 {padding-top: 20px}
.pt-25 {padding-top: 25px}
.pt-30 {padding-top: 30px}

/* padding-bottom */
.pb-10 {padding-bottom: 10px}
.pb-15 {padding-bottom: 15px}
.pb-20 {padding-bottom: 20px}
.pb-25 {padding-bottom: 25px}
.pb-30 {padding-bottom: 30px}

/* padding-left */
.pl-5 {padding-left: 5px}
.pl-10 {padding-left: 10px}
.pl-15 {padding-left: 15px}
.pl-20 {padding-left: 20px}
.pl-25 {padding-left: 25px}
.pl-30 {padding-left: 30px}
.pl-80 {padding-left: 80px}


/* padding-right */
.pr-5 {padding-right: 5px}
.pr-10 {padding-right: 10px}
.pr-15 {padding-right: 15px}
.pr-20 {padding-right: 20px}
.pr-25 {padding-right: 25px}
.pr-30 {padding-right: 30px}

/* width ----------- */

.w-10 {width: 10px}
.w-25 {width: 25px}
.w-30 {width: 25px}
.w-50 {width: 50px}
.w-75 {width: 75px}
.w-100 {width: 100px}
.w-110 {width: 110px}
.w-120 {width: 120px}
.w-125 {width: 125px}
.w-150 {width: 150px}
.w-175 {width: 175px}
.w-180 {width: 180px}
.w-200 {width: 200px}
.w-225 {width: 225px}
.w-250 {width: 250px}
.w-275 {width: 275px}
.w-290 {width: 290px}
.w-300 {width: 300px}
.w-350 {width: 350px}
.w-370 {width: 370px}
.w-400 {width: 400px}
.w-450 {width: 450px}
.w-500 {width: 500px}
.w-550 {width: 550px}

.h-30 {height: 30px}
.h-50 {height: 50px}
.h-80 {height: 80px}
.h-100 {height: 100px}
.h-150 {height: 150px}
.h-200 {height: 200px}
.h-250 {height: 250px}
.h-300 {height: 300px}

.hp-100 {height: 100%}

.wp-20 {width: 20%}
.wp-30 {width: 30%}
.wp-33 {width: 33%}
.wp-50 {width: 50%}
.wp-85 {width: 85%}
.wp-95 {width: 95%}
.wp-100 {width: 100%}

.mw-25 {min-width: 25px}
.mw-50 {min-width: 50px}
.mw-75 {min-width: 75px}
.mw-100 {min-width: 100px}
.mw-125 {min-width: 125px}
.mw-150 {min-width: 150px}
.mw-200 {min-width: 200px}
.mw-250 {min-width: 250px}
.mw-280 {min-width: 280px}
.mw-300 {min-width: 300px}
.mw-350 {min-width: 350px}
.mw-400 {min-width: 400px}

.mxw-25 {max-width: 25px}
.mxw-50 {max-width: 50px}
.mxw-75 {max-width: 75px}
.mxw-100 {max-width: 100px}
.mxw-125 {max-width: 125px}
.mxw-150 {max-width: 150px}
.mxw-200 {max-width: 200px}
.mxw-250 {max-width: 250px}
.mxw-300 {max-width: 300px}
.mxw-320 {max-width: 320px}
.mxw-350 {max-width: 350px}
.mxw-370 {max-width: 370px}
.mxw-400 {max-width: 400px}
.mxw-500 {max-width: 500px}
.mxw-600 {max-width: 600px}
.mxw-700 {max-width: 700px}
.mxw-800 {max-width: 800px}

.mw {min-width: 320px}

/* other ----------- */
.pre-line {white-space: pre-line}
.d-none {display: none}
.d-block {display: block}
.d-flex {display: flex}
.d-flex-wrap {display: flex; flex-wrap: wrap}
.d-flex-wrap-sb {display: flex; flex-wrap: wrap; justify-content: space-between;}
.d-flex-wrap-ac {display: flex; flex-wrap: wrap; align-items: center}
.d-flex-ac {display: flex; align-items: center}
.d-flex-sb {display: flex; justify-content: space-between;}
.d-flex-end {display: flex; justify-content: flex-end;}
.d-flex-center {display: flex; justify-content: center; align-items: center}
.d-flex-wrap-sb-ac {display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap}

.ta-left {text-align: left}
.ta-center {text-align: center}
.ta-right {text-align: right}

.fs-8 {font-size: 8px}
.fs-9 {font-size: 9px}
.fs-10 {font-size: 10px}
.fs-12 {font-size: 12px}
.fs-14 {font-size: 14px}

.fs-10-i {font-size: 10px; font-style: italic;}
.fs-12-i {font-size: 12px; font-style: italic;}
.fs-14-i {font-size: 14px; font-style: italic;}

.fcblack {
    color: #000000;
}

.fcred {
    color: var(--url-color)
}

.fcwhite {
    color: var(--bg-color-main)
}
/* mobile/web ----------- */

.mrweb-10 {margin-right: 10px}
.mrweb-15 {margin-right: 15px}
.mrweb-20 {margin-right: 20px}
.mrweb-25 {margin-right: 25px}
.mrweb-30 {margin-right: 30px}
.mrweb-40 {margin-right: 40px}
.mrweb-50 {margin-right: 50px}

.mlweb-10 {margin-left: 10px}
.mlweb-15 {margin-left: 15px}
.mlweb-20 {margin-left: 20px}
.mlweb-25 {margin-left: 25px}
.mlweb-30 {margin-left: 30px}

.mbweb-10 {margin-bottom: 0}
.mbweb-15 {margin-bottom: 0}
.mbweb-20 {margin-bottom: 0}
.mbweb-25 {margin-bottom: 0}
.mbweb-30 {margin-bottom: 0}

.fs-12-10 {font-size: 12px}
.fs-14-12 {font-size: 14px}
.fs-16-12 {font-size: 16px}

.wrapMobile {flex-wrap: nowrap}

@media (min-width: 0px) and (max-width:450px) {

    .fs-12-10 {font-size: 10px}
    .fs-14-12 {font-size: 12px}
    .fs-16-12 {font-size: 12px}

    .mrweb-10 {margin-right: 0}
    .mrweb-15 {margin-right: 0}
    .mrweb-20 {margin-right: 0}
    .mrweb-25 {margin-right: 0}
    .mrweb-30 {margin-right: 0}
    .mrweb-40 {margin-right: 0}
    .mrweb-50 {margin-right: 0}

    .mlweb-10 {margin-left: 0}
    .mlweb-15 {margin-left: 0}
    .mlweb-20 {margin-left: 0}
    .mlweb-25 {margin-left: 0}
    .mlweb-30 {margin-left: 0}

    .mbweb-10 {margin-bottom: 10px}
    .mbweb-15 {margin-bottom: 15px}
    .mbweb-20 {margin-bottom: 20px}
    .mbweb-25 {margin-bottom: 25px}
    .mbweb-30 {margin-bottom: 30px}

    .wrapMobile {flex-wrap: wrap}
}

.slide-pane {
    display: flex;
    flex-direction: column;
    background: #f8f8f8;
    min-width: 100px;
    height: 100%;
    box-shadow: 0 8px 8px rgba(0,0,0,0.5);
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
    will-change: transform;
}
.slide-pane:focus {
    outline-style: none;
}
.slide-pane_from_right {
    margin-left: auto;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
}
.slide-pane_from_right.content-after-open {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
}
.slide-pane_from_right.content-before-close {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
}
.slide-pane_from_left {
    margin-right: auto;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
}
.slide-pane_from_left.content-after-open {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
}
.slide-pane_from_left.content-before-close {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
}
.slide-pane_from_bottom {
    height: 90vh;
    margin-top: 10vh;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
}
.slide-pane_from_bottom.content-after-open {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
}
.slide-pane_from_bottom.content-before-close {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
}
.slide-pane__overlay {
    z-index: 100;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0,0,0,0);
}
.slide-pane__overlay.overlay-after-open {
    background-color: rgba(0,0,0,0.3);
    transition: background-color 0.5s;
}
.slide-pane__overlay.overlay-before-close {
    background-color: rgba(0,0,0,0);
}
.slide-pane__header {
    display: flex;
    flex: 0 0 64px;
    align-items: center;
    background: #ED1C24;
    color: #FFFFFF;
    height: 64px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}
.slide-pane__title-wrapper {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    min-width: 0;
}
.slide-pane .slide-pane__title {
    font-size: 18px;
    font-weight: normal;
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    padding: 0;
}
.slide-pane__close {
    margin-left: 24px;
    padding: 16px;
    opacity: 0.7;
    cursor: pointer;
}
.slide-pane__close svg {
    width: 12px;
    padding: 0;
}
.slide-pane__content {
    position: relative;
    overflow-y: auto;
    padding: 24px 32px;
    flex: 1 1 auto;
}
.slide-pane__subtitle {
    font-size: 12px;
    margin-top: 2px;
}

.ReactModal__Body--open {
    height: 100vh;
    overflow-y: hidden;
    z-index: 100;
}

@media (min-width: 0px) and (max-width:450px) {
    .slide-pane__content {
        position: relative;
        overflow-y: auto;
        padding: 20px;
        flex: 1 1 auto;
    }
    .slide-pane__close {
        margin-left: 14px;
        padding: 16px;
        opacity: 0.7;
        cursor: pointer;
    }
}

